<template>
  <div>
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <b class="text-dark">{{ tableTitle }}</b>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="mb-0 table-outline"
          hover
          :items="tableItems"
          :fields="tableFields"
          head-color="light"
          no-sorting
          :items-per-page="10"
          pagination
          :table-filter="{label: $t('table_filter')}"
          :items-per-page-select="{label: $t('items_page')}"
        >
          <template #options="{ item }">
            <td class="text-right">
              <CButton size="sm" class="" @click="openCardCountryEdit(item)">
                <CIcon name="cil-pencil" />
              </CButton>
              <!-- <CButton size="sm" class="" @click="openCardCountryDelete(item)">
                <CIcon name="cil-trash" />
              </CButton> -->
            </td>
          </template>
          <template #no-items-view>
            <div class="d-flex align-items-center justify-content-center my-3">
                <span class="mr-2" style="font-size: 30px; font-weight: 600; ">{{ $t('no_items') }}</span>
                <CIcon name="cil-ban" class="text-danger" style="width: 35px; height: 35px;" />
              </div>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: "ListCountry",
  props: {
    tableItems: Array,
  },
  data() {
    return {
      layout: "list",

      // props table Countries
      tableTitle: this.$i18n.t("countries"),
      tableFields: [
        { key: "code", label: this.$t('code'), _classes: "text-center" },
        { key: "language", label: this.$t('language'), _classes: "text-left" },
        { key: "truckHoursLimit",label: this.$t('truck_limit'), _classes: "text-center" },
        { key: "jobsiteSampling", label: this.$t('jobsite_sampling') , _classes: "text-center" },
        { key: "plantSampling", label: this.$t('plant_sampling'), _classes: "text-center" },
        { key: "truckWashing", label: this.$t('truck_washing') ,_classes: "text-center" },
        { key: "zondaEnvironment", label: this.$t('zonda_enviroment'), _classes: "text-center" },
        { key: "options", label: this.$t('options') , _classes: "text-center" },
      ],
    };
  },
  methods: {
    openCardCountryEdit(country) {
      this.$emit("country-edit", {
        showCardCountry: true,
        countrySelected: country,
        inputsDisabled: false,

        btnEventText: this.$t("save"),
        btnEventType: "success",
        cardTitle:this.$t("edit_country"),
      });
    },
    openCardCountryDelete(country) {
      this.$emit("country-delete", {
        showCardCountry: true,
        countrySelected: country,
        inputsDisabled: true,

        notificationType: "danger",
        notification: `You are about to eliminate the country ${country.code}`,

        btnEventText: "Delete",
        btnEventType: "danger",
        cardTitle: "Delete country",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sectionButtons-actions {
  padding: 1em 0 2em 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>